function mapLineBreak(text) {
  return (text || '').replace(/\n/g, '<br/>');
}

function formatCurrencyDisplay(number) {
  try {
    return number.toLocaleString('en-US', 'currency');
  } catch (er) {
    return '-- --';
  }
}

module.exports = {
  mapLineBreak,
  formatCurrencyDisplay,
};
