import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {DatePicker, Button} from 'antd';
import {useOutlet} from 'reconnect.js';
import * as Admin from 'rev.sdk.js/Actions/Admin';
import withPageEntry from '../../withPageEntry';
import {formatCurrencyDisplay} from '../../Utils/TextUtil';
import * as AppActions from '../../AppActions';

function AdminPage(props) {
  const [loading] = useOutlet('loading');
  const [statistics, setStatistics] = React.useState(null);
  const [duration, setDuration] = React.useState([
    moment().startOf('month'),
    moment().endOf('month'),
  ]);

  const fetchStatistics = React.useCallback(async (duration) => {
    AppActions.setLoading(true);

    const date_from = duration[0].valueOf();
    const date_to = duration[1].valueOf();

    try {
      let resp = await Admin.fetchStatistics(date_from, date_to);
      setStatistics(resp);
    } catch (err) {
      console.warn(err);
    } finally {
      AppActions.setLoading(false);
    }
  }, []);

  React.useState(() => {
    fetchStatistics(duration);
  }, []);

  return (
    <Wrapper id="rev-AdminLandingPage">
      <div className="header">
        <h1>首頁</h1>
      </div>

      <div className="major-statistic content">
        <h3>總儀表板</h3>
        <div>
          <Card
            className="user"
            onClick={() => AppActions.navigate('/admin/users/')}
            style={{maxWidth: 200}}>
            <label>會員人數</label>
            <div>
              <span className="number">
                {formatCurrencyDisplay(statistics?.users)}
              </span>
              <span>人</span>
            </div>
          </Card>
          <Card
            className="product"
            onClick={() => AppActions.navigate('/admin/products/')}
            style={{maxWidth: 200}}>
            <label>商品數量</label>
            <div>
              <span className="number">
                {formatCurrencyDisplay(statistics?.products)}
              </span>
              <span>個</span>
            </div>
          </Card>
          <Card
            className="order"
            onClick={() => AppActions.navigate('/admin/orders/')}
            style={{maxWidth: 200}}>
            <label>訂單總數</label>
            <div>
              <span className="number">
                {formatCurrencyDisplay(statistics?.orders)}
              </span>
              <span>張</span>
            </div>
          </Card>
          <Card
            className="article"
            onClick={() => AppActions.navigate('/admin/articles/')}
            style={{maxWidth: 200}}>
            <label>文章數量</label>
            <div>
              <span className="number">
                {formatCurrencyDisplay(statistics?.articles)}
              </span>
              <span>篇</span>
            </div>
          </Card>
        </div>
      </div>

      <div className="order-statistic content">
        <h3>訂單儀表板</h3>

        <div className="search">
          <label>時間區間：</label>
          <DatePicker.RangePicker
            placeholder={['開始日期', '結束日期']}
            defaultValue={[
              moment(new Date(), 'YYYY-MM-DD'),
              moment(
                new Date(new Date().getTime() + 24 * 3600 * 1000 * 30),
                'YYYY-MM-DD',
              ),
            ]}
            value={duration}
            onChange={(dates, dateStrings) => {
              setDuration(dates);
            }}
          />
          <Button
            type="primary"
            onClick={() => fetchStatistics(duration)}
            loading={loading.loading}>
            刷新
          </Button>
        </div>

        <div>
          {statistics?.revenue.length > 0 && (
            <Card
              className="total"
              onClick={() => AppActions.navigate('/admin/orders/')}>
              <label>訂單總收益</label>
              <div>
                <span style={{marginRight: 8}}>收益</span>
                <span className="number" style={{color: '#4e4e4e'}}>
                  {formatCurrencyDisplay(
                    statistics?.revenue.reduce(
                      (prev, curr) => prev.total + curr.total,
                    ),
                  )}
                </span>
                <span>元</span>
              </div>
              <div style={{marginTop: 0}}>
                <span style={{marginRight: 8}}>數量</span>
                <span className="number" style={{color: '#4e4e4e'}}>
                  {formatCurrencyDisplay(
                    statistics?.revenue.reduce(
                      (prev, curr) => prev.count + curr.count,
                    ),
                  )}
                </span>
                <span>張</span>
              </div>
            </Card>
          )}

          {statistics?.revenue
            .sort((a, b) => b.payment_status.length - a.payment_status.length)
            .map((r) => {
              if (r.payment_status === 'success') {
                return (
                  <Card className="payment-success" style={{cursor: 'auto'}}>
                    <label>訂單 / 付款成功</label>
                    <div>
                      <span style={{marginRight: 8}}>收益</span>
                      <span
                        className="number"
                        style={{color: 'var(--primaryColor)'}}>
                        {formatCurrencyDisplay(r.total)}
                      </span>
                      <span>元</span>
                    </div>
                    <div style={{marginTop: 0}}>
                      <span style={{marginRight: 8}}>數量</span>
                      <span className="number">
                        {formatCurrencyDisplay(r.count)}
                      </span>
                      <span>張</span>
                    </div>
                  </Card>
                );
              }

              if (r.payment_status === 'others') {
                return (
                  <Card className="payment-others" style={{cursor: 'auto'}}>
                    <label>
                      訂單 / 其他<span>已成立</span>
                    </label>
                    <div>
                      <span style={{marginRight: 8}}>預期收益</span>
                      <span
                        className="number"
                        style={{color: 'var(--primaryColor)'}}>
                        {formatCurrencyDisplay(r.total)}
                      </span>
                      <span>元</span>
                    </div>
                    <div style={{marginTop: 0}}>
                      <span style={{marginRight: 8}}>數量</span>
                      <span className="number">
                        {formatCurrencyDisplay(r.count)}
                      </span>
                      <span>張</span>
                    </div>
                  </Card>
                );
              }

              return null;
            })}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;

  & > .header {
    margin-bottom: 10px;

    & > h1 {
      font-size: 32px;
    }
  }

  & > .content {
    margin-bottom: 25px;

    & > h2 {
      color: #4d4d4d;
    }

    & > div {
      display: flex;
      flex-wrap: wrap;
    }

    & > div.search {
      margin: 15px 10px;
      display: flex;
      align-items: center;

      & > label {
        margin-right: 10px;
      }

      & > div {
        margin-right: 20px;
      }
    }
  }
`;

const Card = styled.div`
  margin: 10px;
  max-width: 250px;
  width: 100%;
  padding: 15px 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  cursor: pointer;
  color: #4e4e4e;

  & > label {
    border-bottom: 1px solid #4e4e4e;
    padding-bottom: 10px;
    font-weight: 400;
    letter-spacing: 0.15rem;
  }

  & > div {
    margin-top: 10px;
    text-align: right;

    & > span.number {
      margin-right: 5px;
      font-weight: 500;
      font-size: 2.2rem;
      color: var(--primaryColor);
    }
  }

  :hover {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }
`;

export default withPageEntry(AdminPage);
